import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"faq"} />
      <Helmet>
        <title>FAQ | Часті запитання в гольф-клубі Fairway Dreams</title>
        <meta
          name={"description"}
          content={"Відповіді на ваші запитання, покращення гри!"}
        />
        <meta
          property={"og:title"}
          content={"FAQ | Часті запитання в гольф-клубі Fairway Dreams"}
        />
        <meta
          property={"og:description"}
          content={"Відповіді на ваші запитання, покращення гри!"}
        />
        <meta
          property={"og:image"}
          content={"https://start.novirexborn.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://start.novirexborn.com/img/strategy.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://start.novirexborn.com/img/strategy.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://start.novirexborn.com/img/strategy.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://start.novirexborn.com/img/strategy.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://start.novirexborn.com/img/strategy.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://start.novirexborn.com/img/strategy.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="100px 0 100px 0"
        background="#ffffff"
        quarkly-title="FAQ-11"
      >
        <Text
          margin="0px 0px 30px 0px"
          font="normal 900 52px/1.2 --fontFamily-sans"
          color="#0e1940"
          text-align="center"
          lg-margin="0px 0px 50px 0px"
          sm-font="normal 900 36px/1.2 --fontFamily-sans"
        >
          Наш розділ поширених запитань
        </Text>
        <Text margin="0px 0px 40px 0px" font="--lead" color="--darkL2">
          Шукаєте відповіді? Ви потрапили в потрібне місце! У гольф-клубі
          Fairway Dreams ми розуміємо, що у вас можуть виникнути запитання щодо
          наших об'єктів, послуг або вашого членства. Ми склали список
          найпоширеніших запитань, щоб допомогти внести ясність і покращити ваш
          досвід у нашому клубі.
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="16px 24px"
          lg-grid-template-columns="1fr"
        >
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 16px 0px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Який графік роботи клубу?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Наш клуб відкритий від світанку до заходу сонця, сім днів на
                тиждень, що дозволяє вам грати і тренуватися у зручний для вас
                час.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Чи потрібно бути членом клубу, щоб грати?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Нечлени клубу можуть грати на Fairway Dreams. Однак для них
                необхідне попереднє бронювання, а гості-члени клубу отримують
                пріоритетний доступ і знижку.
              </Text>
            </Box>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 16px 0px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Які переваги включає членство в клубі?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Члени клубу користуються необмеженим доступом до поля для
                гольфу, тренувальних майданчиків, клубного будинку та
                спеціальних заходів. Також надаються знижки в професійному
                магазині та ресторанах.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Чи можу я провести приватний захід у клубі?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Так, наш клуб доступний для проведення приватних заходів. Ми
                пропонуємо різноманітні приміщення, які ідеально підходять для
                ділових зустрічей, весіль тощо. Будь ласка, зв'яжіться з нами
                для отримання інформації про бронювання та наявність вільних
                місць.
              </Text>
            </Box>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 16px 0px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Які види навчання гольфу ви пропонуєте?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Ми пропонуємо цілий ряд варіантів навчання, включаючи приватні
                уроки, групові клініки та юніорські програми, всі під
                керівництвом сертифікованих професіоналів гольфу.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Як я можу покращити свою гру на ваших тренувальних майданчиках?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Наші тренувальні майданчики включають в себе повнорозмірне поле
                для гольфу, майданчик для паттінгу та чіпінгу, а також
                тренувальний бункер. Використання цих об'єктів може значно
                покращити різні аспекти вашої гри.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://start.novirexborn.com/img/6.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="20px 0px 8px 0px" font="--headline3" color="--dark">
                Потрібна додаткова інформація?
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Якщо ви не знайшли відповіді на своє питання в нашому списку
                поширених запитань, не соромтеся звертатися до нас. Наші
                співробітники завжди готові надати вам необхідну інформацію, щоб
                зробити ваш час у гольф-клубі Fairway Dreams максимально
                приємним.
              </Text>
            </Box>
          </Box>
        </LinkBox>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
